import React, { useEffect, useState } from "react";
import { ButtonLink } from "../generic/button-link";
import { AuthenticationState } from "../../model/state/authentication-state";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { Icon } from "semantic-ui-react";
import { UserInfoResponse } from "../../model/dto/response";
import { userService } from "../../services/authentication-redux-service";
import { OnboardingStatus } from "../../model/entities/onboarding-status";
import { TypeInvestisseur } from "../../model/entities/types-investisseur";
import { CategorieEtablissement } from "../../model/entities/categorie-etablissement";

interface Props {
  onActionSuccess?: () => void;
}

export function RemerciementOnboardingCitoyen({}: Props) {
  const [userInfo, setUserInfo] = useState<UserInfoResponse>();
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>((state) => state.authentication);

  useEffect(() => {
    userService
      .getUserInfo(loginProps?.oauth?.userId, loginProps?.oauth)
      .then((response) => {
        setUserInfo(response);
      });
  }, [loginProps?.oauth]);

  const infoBox = {
    backgroundColor: "#D0EAFE",
    color: "#000000",
    padding: "10px 20px",
    display: "flex",
    borderRadius: "4px",
    maxWidth: 'auto',
    margin: '0 auto',
  };
  

  const isPartiellementComplet = loginProps?.user?.OnboardingStatus?.Code === OnboardingStatus.PARTIELLEMENT_COMPLET;
  const isPresqueComplet = loginProps?.user?.OnboardingStatus?.Code === OnboardingStatus.PRESQUE_COMPLET;
  const isMailValid = loginProps?.user?.IsMailValid;
  const isJustifPiecesDone = userInfo?.IsPiecesJustifDone || userInfo?.Etablissement?.IsPieceJustifDone;
 
  return (
    <div>
      <div style={{ textAlign: "center", fontWeight: "bold" }}>
        {isPartiellementComplet && loginProps?.user?.Etablissement !== null && loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode !== null && loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode !== "" ? 
            <p style={{ fontSize: "20px", marginBottom: "8px" }}>Vous y êtes presque !</p> :
            <p style={{ fontSize: "20px", marginBottom: "8px" }}>C’est parti !</p>
        }
        

        {isPartiellementComplet && isMailValid && (
          <div style={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <p style={{ marginBottom: '0', textAlign: 'left' }}>
              Vous pourrez investir après soumission de votre état civil et de votre adresse postale.
            </p>
          </div>
        )}

        {isPartiellementComplet && !isMailValid && loginProps?.user?.Etablissement !== null && loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode !== null && loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode !== "" && 
        (
          <div style={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <div style={{ marginLeft: "8px" }}>
              <p style={{ marginBottom: '0', textAlign: 'left' }}>
              Vous venez de recevoir un lien de validation de votre adresse mail ! 
              </p>
              <p style={{ marginBottom: '0',textAlign: 'left', fontWeight:"normal" }}>
              La vérification de votre mail est nécessaire pour pouvoir donner. Si vous n’avez pas reçu le lien, pensez à vérifier vos courriers indésirables ou promotionnels.
              </p>
              <p style={{ textAlign: 'left', fontWeight:"normal" }}>Pour pouvoir donner, il vous faudra également compléter les informations manquantes sur votre page profil.</p>
            </div>
          </div>
        )
        }

        {isPartiellementComplet && !isMailValid && loginProps?.user?.Etablissement === null && 
        (
          <div style={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <div style={{ marginLeft: "8px" }}>
              <p style={{ marginBottom: '0', textAlign: 'left' }}>
                Nous vous avons transmis un mail avec un lien sur lequel cliquer afin de vérifier que l’adresse mail de votre compte vous appartient effectivement.
              </p>
              <p style={{ fontStyle: "italic", textAlign: 'left' }}>
                (Astuce : si vous ne l’avez pas reçu, pensez à vérifier vos spams). Vous pourrez investir après soumission de votre état civil et de votre adresse postale.
              </p>
            </div>
          </div>
        )}

        {isPresqueComplet && isMailValid && !isJustifPiecesDone && (
          <div style={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
              <p style={{ marginBottom: '0', textAlign: 'left', marginLeft: "8px" }}>
                Vous pouvez commencer à investir dès à présent. Attention pensez à finaliser votre inscription en transmettant une copie de votre pièce d’identité depuis votre page profil.
              </p>
          </div>
        )}

        {isPresqueComplet && !isMailValid && !isJustifPiecesDone && 
        loginProps?.user?.Etablissement !== null && loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode !== null && loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode !== "" && 
        (
          <div style={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <div>
              <p style={{ marginBottom: '0', textAlign: 'left', marginLeft: "8px"  }}>
              Vous venez de recevoir un lien de validation de votre adresse mail !
              </p>
              <p style={{ marginBottom: '0', textAlign: 'left', marginLeft: "8px"  }}>
              La vérification de votre mail est nécessaire pour pouvoir donner. Si vous n’avez pas reçu le lien, pensez à vérifier vos courriers indésirables ou promotionnels.
              </p>
            </div>
          </div>
        )
      }
      { isPresqueComplet && !isMailValid && !isJustifPiecesDone && 
        loginProps?.user?.Etablissement === null &&
        (
          <div style={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <div style={{ marginLeft: "8px" }}>
              <p style={{ marginBottom: '0', textAlign: 'left' }}>
                Nous vous avons transmis un mail avec un lien sur lequel cliquer afin de vérifier que l’adresse mail de votre compte vous appartient effectivement (Astuce : si vous ne l’avez pas reçu, pensez à vérifier vos spams). Vous pouvez commencer à investir dès à présent.
              </p>
              <p style={{ textAlign: 'left' }}>
                Attention pensez à finaliser votre inscription en transmettant une copie de votre pièce d’identité depuis votre page profil.
              </p>
            </div>
          </div>
        )
      }

        {isPresqueComplet && isMailValid && isJustifPiecesDone && (
          <div style={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <p style={{ marginBottom: '0', textAlign: 'left', marginLeft: "8px"  }}>
              Vous pouvez commencer à investir dès à présent.
            </p>
          </div>
        )}

        {isPresqueComplet && !isMailValid && isJustifPiecesDone && 
        loginProps?.user?.Etablissement !== null && loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode !== null && loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode !== "" && 
          (
            <div style={infoBox}>
              <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
              <p style={{ marginBottom: '0', textAlign: 'left', marginLeft: "8px"  }}>
              Vous venez de recevoir un lien de validation de votre adresse mail !
              </p>
              <p style={{ marginBottom: '0', textAlign: 'left', marginLeft: "8px"  }}>
              La vérification de votre mail est nécessaire pour pouvoir donner. Si vous n’avez pas reçu le lien, pensez à vérifier vos courriers indésirables ou promotionnels.
              </p>
            </div>
          )
        }
        { isPresqueComplet && !isMailValid && isJustifPiecesDone  && loginProps?.user?.Etablissement === null &&
        (
          <div style={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <p style={{ marginBottom: '0', textAlign: 'left', marginLeft: "8px"  }}>
            Nous vous avons transmis un mail avec un lien sur lequel cliquer afin de vérifier que l’adresse mail de votre compte vous appartient effectivement (Astuce : si vous ne l’avez pas reçu, pensez à vérifier vos spams). Vous pouvez commencer à investir dès à présent.
            </p>
          </div>
        )
      }

        <div style={{ marginTop: "18px" }}>
          {isPartiellementComplet && loginProps?.user?.Etablissement !== null && loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode !== null && loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode !== "" ? 
          (
            <ButtonLink label="Compléter mon profil" to="/profil" />
          ) : (
            <ButtonLink label="Découvrir les projets" to="/projets" />
          )}
        </div>
      </div>
    </div>
  );
}
