import { addDays, parseISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Header, Icon, Modal, Popup} from "semantic-ui-react";
import { DefaultResponse, DepartementListResponse, InvestResponse, ProfilCode} from "../../../../../model/dto/response";
import Projet from "../../../../../model/projet";
import { projetService } from "../../../../../services/projet-service";
import { ProjectInfos, References } from "../../project-detail";
import { AuthenticationState } from "../../../../../model/state/authentication-state"
import { ApplicationState } from "../../../../../store"
import { useSelector } from "react-redux";
import { StatutProjet } from "../../../../../model/entities/statut-projet";
import { AlerterUserBody } from "../../../../../model/dto/body/alerter-user-body";
import { SubmitButton } from "../../../../generic/submit-button";
import { DemandeDebouclageForm } from "../../../forms/demande-debouclage-form";
import { ValidateDebouclageForm } from "../../../forms/validate-debouclage-form";
import { FinalisationForm } from "../../../forms/finalisation-form";
import { useNavigate } from "react-router-dom";
import { OnboardingStatus } from "../../../../../model/entities/onboarding-status";
import { AlerterUserRepriseBody } from "../../../../../model/dto/body/alerter-user-reprise-body";
import { CategorieEtablissement } from "../../../../../model/entities/categorie-etablissement";
import { TypeEmprunt } from "../../../../../model/entities/type-emprunt";
import { referenceService } from "../../../../../services/reference-service";
import { Departement } from "../../../../../model/entities/departement";
import { ForceEcheanceForm } from "../../../forms/force-echeance-form";
import { ProlongationForm } from "../../../forms/prolongation-form";
import { investmentService } from "../../../../../services/investment-service";
import { EcheancierProjet } from "../../../../../model/entities/echeancier-projet";
import { ProcessProjectToAVenirStatutBody } from "../../../../../model/dto/body/process-to-avenir-statut-body";
import { PublishBody } from "../../../../../model/dto/body/publish-body";
import { TypeCollecte } from "../../../../../model/entities/type-collecte";
import { LinkButton } from "../../../../generic/link-button";
import './financement-participatif.css'
import ProjetObjectifs from "../../../../../model/entities/projet-objectifs";
import { calculateNextObjectif, calcultePurcentBar, isUnderFirstObjectif, returnLastObjectifAttemptedOrder } from "../../../../../helpers/objectifs-helper";
import ProgressBar from "@ramonak/react-progress-bar";

type Props = {
  project: Projet;
  projetInfo: ProjectInfos;
  profile: ProfilCode;
  isUserAlerted?: boolean;
  score?: number;
  reponseNumeroList?: number[];
  setIsUserAlerted: (isUserAlerted: boolean) => void;
  nombreInvestisseur: number;
  categorieEtablissementCode?: string;
  onPublishSuccess: (response: Response) => void;
  endDate: Date;
  reload: () => void;
  references: References;
  objectifs?: ProjetObjectifs[];
};

export default function FinancementParticipatif({
    project,
    projetInfo,
    profile,
    isUserAlerted,
    score,
    reponseNumeroList,
    objectifs,
    setIsUserAlerted,
    nombreInvestisseur,
    categorieEtablissementCode,
    onPublishSuccess,
    endDate,
    reload,
    references
}: Props) {

    const navigate = useNavigate()
    const [isEdited, setIsEdited] = useState(false)
    const [secondOpen, setSecondOpen] = useState(false)
    const [secondPopupMessage, setSecondPopupMessage] = useState('Cliquez ici pour être alerté ou ne plus être alerté du lancement de la levée!')
    const [isNotSameHorizon, setIsNotSameHorizon] = useState(false)
    const [thirdOpen, setThirdOpen] = useState(false)
    const [suspendedOpen, setSuspendedOpen] = useState(false)
    const [suspendedPopupMessage, setSuspendedPopupMessage] = useState('Cliquez ici pour être alerté ou ne plus être alerté de la reprise de la levée!')
    const [departementReferenceList, setDepartementReferenceList] = useState<Departement[]>([])
    const [isModalNextEcheanceOpen, setIsModalNextEcheanceOpen] = useState(false)
    const [echeanciersProjet, setEcheanciersProjet] = useState<EcheancierProjet[]>([])
    
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const [windowWidth, setWindowWith] = useState(window.innerWidth)

    useEffect(()=>{
        if(loginProps.isAuthenticated && profile === ProfilCode.INVESTISSEUR) {
            referenceService.getAllDepartementList(loginProps?.oauth)
            .then(response => {
                return response.json() as Promise<DepartementListResponse>
            })
            .then(response => {
                setDepartementReferenceList(response.DepartementList!)
            })
        }
        if(project && profile === ProfilCode.VILLYZ) {
            investmentService.getEcheanciersProjetByProjetId(project.Id!, loginProps?.oauth)
            .then(response => setEcheanciersProjet(response.EcheanciersProjet!))
        }
    }, [])

    // On gère les cas de redimensionnement de la fenêtre
    useEffect(() => {
        const handleSize = () => setWindowWith(window.innerWidth)
        window.addEventListener('resize', handleSize);

        // Nettoyage de l'écouteur d'événements quand le composant est démonté
        return () => window.removeEventListener('resize', handleSize);
    })

    // On récupère la liste des codes des départements restreints
    const displayRestrictedDepartementsCode = useMemo(() => {
    let toReturn = ""
    if(projetInfo.restrictedDepartements) {
        for(let i = 0; i < projetInfo.restrictedDepartements.length; i++) {
            if(i !== 0) {
                toReturn += ", "
            }
            toReturn += departementReferenceList.find(dep => dep.Id === projetInfo.restrictedDepartements![i].DepartementId)?.Designation + 
            "(" + departementReferenceList.find(dep => dep.Id === projetInfo.restrictedDepartements![i].DepartementId)?.Code + ")"
        }
    }
    return toReturn
    }, [departementReferenceList]);

    // On vérifie si le citoyen peut investir
    const isNotRestricted = useMemo(() => {
        if(!loginProps.oauth || !project.IsRestrictedZoneGeo) {
            return true
        }

        if(!loginProps.user?.Etablissement === undefined) {
            if(projetInfo.restrictedDepartements && projetInfo.restrictedDepartements.length > 0 ) {
                for(let i = 0; i < projetInfo.restrictedDepartements.length; i++) {
                    if(loginProps.user?.DepartementId === projetInfo.restrictedDepartements[i].DepartementId) {
                        return true
                    } 
                }
                return false
            } 
            return true 
        }
        else if(loginProps.user?.Etablissement !== undefined) {
            if(projetInfo.restrictedDepartements && projetInfo.restrictedDepartements.length > 0 ) {
                for(let i = 0; i < projetInfo.restrictedDepartements.length; i++) {
                    if(loginProps.user?.Etablissement?.DepartementId === projetInfo.restrictedDepartements[i].DepartementId) {
                        return true
                    } 
                }
                return false
            } 
            return true 
        }
    }, []);

    const statutTermines = [
        StatutProjet.CLOTURE, 
        StatutProjet.DEBOUCLAGE_DEMANDE, 
        StatutProjet.DEBOUCLAGE_POSSIBLE,
        StatutProjet.DEBOUCLAGE_VALIDE,
        StatutProjet.DEBOUCLAGE_FINALISE,
        StatutProjet.FINANCE
    ]

    const statutPrePublies = [
        StatutProjet.INCOMPLET,
        StatutProjet.COMPLET,
        StatutProjet.EN_TRAITEMENT,
        StatutProjet.A_VENIR,
        StatutProjet.BIENTOT_DISPONIBLE,
        StatutProjet.INFORMATION_MANQUANTE,
        StatutProjet.ANNULE,
        StatutProjet.REFUSE
    ]

    const statutsClotures = [
        StatutProjet.CLOTURE, 
        StatutProjet.DEBOUCLAGE_DEMANDE,
        StatutProjet.DEBOUCLAGE_POSSIBLE,
        StatutProjet.DEBOUCLAGE_VALIDE,
        StatutProjet.DEBOUCLAGE_FINALISE
    ]

    const TypeEmpruntLibelle = [
        {
            Code: TypeEmprunt.PRET,
            Libelle: "Prêt"
        },
        {
            Code: TypeEmprunt.TITRES,
            Libelle: "Titres participatifs"
        },
        {
            Code: TypeEmprunt.OBLIGATIONS,
            Libelle: "Obligations"
        }
    ]

    useEffect(() => {
        const reponseCinq: boolean = reponseNumeroList?.find(reponseNumero => reponseNumero === 5) !== undefined;
        const reponseSix: boolean = reponseNumeroList?.find(reponseNumero => reponseNumero === 6) !== undefined;
        const reponseSept: boolean = reponseNumeroList?.find(reponseNumero => reponseNumero === 7) !== undefined;
       
        if(reponseSept || reponseSix && project.MaturiteCreditCitoyen && project.MaturiteCreditCitoyen >= 84 || reponseCinq && project.MaturiteCreditCitoyen && project.MaturiteCreditCitoyen <= 84) {
            setIsNotSameHorizon(false)
        }
        else {
            setIsNotSameHorizon(true)
        }
    }, [reponseNumeroList, project])

    const nextObjectif = calculateNextObjectif(project.MontantCagnotteActuel!, objectifs!, project.MontantMaximalFinancement!)

    const pourcentageCollecte = useMemo(() => {
        if(project.MontantCagnotteActuel ) {
            const objectifStart = project.MontantCagnotteActuel! / 100
            return calcultePurcentBar(objectifStart, nextObjectif)
        }
        else {
            return 0
        }
    }, [objectifs, nextObjectif])

    const displayAvertissement = useMemo(() => {
        return ((score || score === 0)  && ((categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE && score < 60) || (categorieEtablissementCode === CategorieEtablissement.SOCIETE && score < 90))) || isNotSameHorizon
    }, [score, categorieEtablissementCode, isNotSameHorizon])

    const onButtonClick = () => {
        if(displayAvertissement){
            setThirdOpen(true) 
        }
        else{
            setThirdOpen(false)
            navigate(`/projets/${project.Id}/investissement/v2`)
        }
    }
  
    const handleButtonClick = () => {
        if(loginProps.isAuthenticated) {
            const body: AlerterUserBody = {
            IsAlert: !isUserAlerted
            }
            projetService.alerterUser(body, project.Id!, loginProps?.oauth)

            if(!isUserAlerted) {
            setSecondPopupMessage('Vous serez alerté du lancement de la levée!')
            }
            else {
            setSecondPopupMessage('Vous ne serez plus alerté')
            }

            setIsUserAlerted(!isUserAlerted)
        }
        else {
            setSecondOpen(true)
        }
    }

    const handleSuspensionButtonClick = () => {
        if(loginProps.isAuthenticated) {
            const body: AlerterUserRepriseBody = {
            IsAlert: !isUserAlerted
            }
            projetService.alerterUserReprise(body, project.Id!, loginProps?.oauth)

            if(!isUserAlerted) {
            setSuspendedPopupMessage('Vous serez alerté de la reprise de la levée!')
            }
            else {
            setSecondPopupMessage('Vous ne serez plus alerté')
            }

            setIsUserAlerted(!isUserAlerted)
        }
        else {
            setSuspendedOpen(true)
        }
    }

    const isProjetEnTraitementEligible = () => {
        if(
            project.StatutProjetCode === StatutProjet.INCOMPLET || 
            project.StatutProjetCode === StatutProjet.COMPLET
        ) {
            
            return project.Titre !== null && project.DescriptionCourt !== null && project.MontantFinancementCitoyenDemande ? true : false
        } 
    }

    const processProjectToAvenirBody: ProcessProjectToAVenirStatutBody = {
        IsEmprunt: true
    }

    const publishBody : PublishBody = {
        IsEmprunt: true
    }

    
    const statutNotAvailableToSwicth = [
        StatutProjet.INCOMPLET,
        StatutProjet.COMPLET,
        StatutProjet.EN_TRAITEMENT,
        StatutProjet.A_VENIR,
        StatutProjet.INFORMATION_MANQUANTE,
        StatutProjet.ANNULE,
        StatutProjet.REFUSE
    ]

    const isSwitchAvailable = (): boolean => {
        if(loginProps.oauth?.profilCode ===  ProfilCode.PORTEUR_PROJET || loginProps.oauth?.profilCode === ProfilCode.VILLYZ){
          return false
        }
        return !statutNotAvailableToSwicth.includes(project.StatutDonProjetCode!)
    }

    const blueVillyz = '#005870'
    const validatedGreenColor = '#048A00'
    const toCompleteGreenColor = '#82C580'
    const baseBgGrey = '#e5e7eb'

    return (
        <div className="financement-main-container">
            <div>
                <div className="financement-collected-money-block"
                >
                    {(statutPrePublies.find(statut => statut === project.StatutProjetCode)) && (
                        <>
                        {project.MontantFinancementCitoyenDemande ? project.MontantFinancementCitoyenDemande.toLocaleString("fr-FR") + "€ " : " 0€ "}
                        <span 
                        style={{ color:"#7F7F7F" }}
                        >{project.MontantFinancementCitoyenDemande! >= 1 ? "recherchés" : "recherché"}</span>
                        </>
                    )}
                    {(statutTermines.find(statut => statut === project.StatutProjetCode) || project.StatutProjetCode === StatutProjet.PUBLIE) && (
                        <>
                        {project.MontantCagnotteActuel ? (project.MontantCagnotteActuel / 100).toLocaleString("fr-FR") + "€ " : " 0€ "}
                        <span 
                         className="abstract-color"
                        >{project.MontantCagnotteActuel! >= 1 ? "collectés" : "collecté"}</span>
                        </>
                    )}
                </div>
                {!statutTermines.find(statut => statut === project.StatutProjetCode) && (objectifs === undefined || objectifs.length <=0) && (
                <div className="financement-progress-bar-container">
                    <ProgressBar 
                        completed={pourcentageCollecte}
                        bgColor={blueVillyz}
                        isLabelVisible={false}
                        maxCompleted={100}
                        animateOnRender
                        height="15px"
                        borderRadius="4px"

                    />
                    <div style={{ display: "flex", justifyContent:"center"}}>
                       <p style={{fontSize:"14px", color:"#404040", fontWeight:'bolder'}}>
                            {nextObjectif.toLocaleString("fr-FR")}€{" "}
                            <span style={{ color:"#7F7F7F" }}>recherchés</span>
                        </p>    
                    </div>
                </div>
                )}
                {!statutTermines.find(statut => statut === project.StatutProjetCode) && objectifs && objectifs.length > 0 &&  (
                    <div className={isUnderFirstObjectif(project.MontantCagnotteActuel!, objectifs) ? "financement-progress-bar-container" :"financement-progress-bar-container attempted-objectif"}>
                        <ProgressBar 
                            completed={pourcentageCollecte}
                            bgColor={isUnderFirstObjectif(project.MontantCagnotteActuel!, objectifs) ? blueVillyz : validatedGreenColor}
                            isLabelVisible={false}
                            baseBgColor={isUnderFirstObjectif(project.MontantCagnotteActuel!, objectifs) ? baseBgGrey : toCompleteGreenColor}
                            maxCompleted={100}
                            animateOnRender
                            height="15px"
                            borderRadius="6px"
                        />
                        {isUnderFirstObjectif(project.MontantCagnotteActuel!, objectifs) ?
                        (
                            <div style={{ display: "flex", marginTop:5, justifyContent:"center"}}>
                                <p style={{fontSize:"14px", color:"#404040", fontWeight:'bolder'}}>
                                    {nextObjectif.toLocaleString("fr-FR")}€{" "}
                                    <span style={{ color:"#7F7F7F" }}>recherchés</span>
                                </p>
                            </div>
                        )
                        :
                        (
                            <div className="financement-progress-bar-text-container">
                                <p className="progress-text-attempted"><img className="attempted-objectif-icon" src="/img/icons/check.svg" alt="logo palier validé"/><span>{returnLastObjectifAttemptedOrder(project.MontantCagnotteActuel!, objectifs)} objectif atteint !</span></p>
                                <p>
                                    <span className="progress-amount">{nextObjectif.toLocaleString("fr-FR")}€{" "}</span>
                                    <span className="progress-text-search">recherchés</span>
                                </p>
                            </div>
                        )
                        }
                        {project.StatutProjetCode === StatutProjet.BIENTOT_DISPONIBLE && (
                            <div style={{textAlign: "center", marginTop:"10px", fontWeight:'bolder'}}>
                                    <span style={{ fontSize:"14px", color:"#7F7F7F" }}
                                    >Pour ne pas manquer l'opportunité :</span>
                            </div>
                        )}
                    </div>
                )}
                {statutTermines.find(statut => statut === project.StatutProjetCode) && (
                    <div className="financement-progress-bar-container">
                        <ProgressBar
                            bgColor={blueVillyz}
                            completed={100}
                            maxCompleted={100}
                            isLabelVisible={false}
                            height="15px"
                        />
                        {project.StatutProjetCode === StatutProjet.FINANCE &&
                        <div style={{display: "flex", justifyContent: "center", marginTop:5}}>
                            <p style={{fontSize:"14px", color:"#404040", fontWeight:'bolder'}}>
                                    {nombreInvestisseur || ""}
                                    <span style={{ color:"#7F7F7F" }}>{nombreInvestisseur > 1 ? " prêteurs" : nombreInvestisseur > 0 ? " prêteur" : "aucun prêteur"}</span>
                            </p>
                        </div>
                        }
                    </div>
                )}
            </div>
            <div className="financement-container-button-main-action">
                {profile === ProfilCode.PORTEUR_PROJET && isProjetEnTraitementEligible() && (
                <SubmitButton 
                    label="Publier à Villyz"
                    action={() => projetService.publishProjet(project.Id!, publishBody, loginProps?.oauth)}
                    onActionSuccess={onPublishSuccess}
                    style={{
                        padding: '10px 20px',
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: 600
                    }}
                />
                )}
                {profile === ProfilCode.VILLYZ && project.StatutProjetCode === StatutProjet.EN_TRAITEMENT && (
                <SubmitButton 
                    label="Rendre public"
                    action={() => projetService.ProcessProjectToAVenirStatut(project.Id!,  processProjectToAvenirBody, loginProps?.oauth)}
                    onActionSuccess={onPublishSuccess}
                    style={{
                        padding: '10px 20px',
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: 600
                    }}
                />
                )}
                {profile === ProfilCode.PORTEUR_PROJET && project.StatutProjetCode === StatutProjet.PUBLIE && endDate && addDays(endDate, -5) <= new Date() && (
                <>
                    <Button 
                        size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                        color="blue" 
                        fluid
                        onClick={() => setIsEdited(true)}
                    >
                    Prolonger la cagnotte
                    </Button>
                    <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                    <Modal.Header>Prolonger la cagnotte</Modal.Header>
                    <Modal.Content>
                        <ProlongationForm 
                            projet={project}
                            onSubmitSuccess={() => {
                                setIsEdited(false); 
                                reload();
                            }}
                        />
                    </Modal.Content>
                    </Modal>
                </>
                )}
                {profile === ProfilCode.PORTEUR_PROJET && project.StatutProjetCode === StatutProjet.DEBOUCLAGE_POSSIBLE && endDate && (
                <>
                    <Button
                        size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                        color="blue" 
                        fluid
                        onClick={() => setIsEdited(true)}
                    >
                        Demander le débouclage
                    </Button>
                    <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                    <Modal.Header>Demande de débouclage</Modal.Header>
                    <Modal.Content>
                        <DemandeDebouclageForm 
                            projet={project}
                            onSubmitSuccess={(response: DefaultResponse) => {
                                setIsEdited(false);
                                reload();
                            }}
                            isDon={false}
                        />
                    </Modal.Content>
                    </Modal>
                </>
                )}
                {profile === ProfilCode.VILLYZ && project.StatutProjetCode === StatutProjet.DEBOUCLAGE_DEMANDE && (
                <>
                    <Button
                        size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                        color="blue" 
                        fluid
                        onClick={() => setIsEdited(true)}
                    >
                        Valider le débouclage
                    </Button>
                    <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                        <Modal.Header>Validation du débouclage</Modal.Header>
                        <Modal.Content>
                            <ValidateDebouclageForm
                                projetId = {project.Id}
                                onActionSuccess = { () => { reload() }}
                                onActionFailure = { () => { reload() }}
                                echeanciersProjet = {echeanciersProjet}
                                isPageDon={false}
                            />
                        </Modal.Content>
                    </Modal>
                </>
                )}
                {profile === ProfilCode.PORTEUR_PROJET && project.StatutProjetCode === StatutProjet.DEBOUCLAGE_VALIDE && !project.ProjectIsPayout && (
                <>
                    <Button
                        size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                        color="blue" 
                        fluid
                        onClick={() => setIsEdited(true)}
                    >
                        Finaliser le débouclage
                    </Button>
                    <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                        <Modal.Header>Finalisation de la demande de débouclage​</Modal.Header>
                        <Modal.Content>
                            <FinalisationForm
                            projet = {project}
                            onSubmitSuccess = { (response: InvestResponse ) => {
                                setIsEdited(false);
                                if(response.IsTraitementOk) {
                                window.location.href = response.RedirectURL!
                                }
                            }}
                            />
                        </Modal.Content>
                    </Modal>
                </>
                )}
                {profile === ProfilCode.VILLYZ && project.StatutProjetCode === StatutProjet.FINANCE && (
                <>
                    <Button
                        size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                        color="blue" 
                        fluid
                        onClick={() => setIsModalNextEcheanceOpen(true)}
                    >
                        Forcer prochaine échéance
                    </Button>
                    <Modal onClose={() => setIsModalNextEcheanceOpen(false)} open={isModalNextEcheanceOpen}>
                        <Modal.Header>Déclenchement de la prochaine échéance</Modal.Header>
                        <Modal.Content>
                            <ForceEcheanceForm 
                                projetId={project.Id}
                            />
                        </Modal.Content>
                    </Modal>
                </>
                )}
                {profile === ProfilCode.INVESTISSEUR && 
                !loginProps.user?.Etablissement &&
                loginProps.user?.IsMailValid && project.StatutProjetCode === StatutProjet.PUBLIE && 
                (loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.PRESQUE_COMPLET ||
                loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.ERREUR ||
                loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.COMPLET ||
                loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.VALIDE) &&
                isNotRestricted && (
                    <div className={project.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON ? "" : "no-switch-link"}>
                        <Button 
                            color="blue"
                            fluid
                            onClick={onButtonClick}
                            size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                        >
                            Investir
                        </Button>
                        <Modal
                            open={thirdOpen}
                            onClose={() => setThirdOpen(false)}
                        >
                            <Modal.Header>Avertissement</Modal.Header>
                            <Modal.Content>
                                <Modal.Description css={{textAlign: "center", fontSize:"1.25rem"}}>
                                    Un investissement dans un projet de financement participatif présente des risques de perte totale du capital.
                                    Pour avoir plus d’information sur notre méthode de sélection des projets, vous pouvez consultez notre page dédiée aux&nbsp;
                                    <a href="/risques" target="_blank">risques</a>
                                </Modal.Description>
                                <div 
                                style={{display: "flex", justifyContent:"center", marginTop:"15px"}}
                                >
                                        <SubmitButton
                                        label="J'ai compris"
                                        onClick={() => navigate(`/projets/${project.Id}/investissement/v2`)}
                                        style={{height: "40px", width: "150px", fontSize:"1.1rem"}}
                                    />
                                </div>
                            </Modal.Content>
                        </Modal>
                    </div>
                )}
                {profile === ProfilCode.INVESTISSEUR && project.StatutProjetCode === StatutProjet.BIENTOT_DISPONIBLE && (
                    <div onClick={handleButtonClick}>
                    <Modal
                    open={secondOpen}
                    onClose={() => setSecondOpen(false)}
                    closeOnDocumentClick
                    trigger={
                        <Popup 
                            content={secondPopupMessage}
                            position='bottom center'
                            size='mini'
                            on='hover'
                            pinned
                            mouseEnterDelay={200}
                            mouseLeaveDelay={200}
                            hideOnScroll
                            trigger={
                                <div className="financement-button-main-action">          
                                    <Button
                                        color="blue" 
                                        fluid
                                    >
                                        <Icon css={{color:"white !important"}} size="large" name={isUserAlerted ? "bell" : "bell outline"}/>
                                        <span
                                        style={{verticalAlign:"middle"}}
                                        >Être alerté du lancement</span>
                                    </Button>
                                </div>
                            }
                        />    
                    }
                    >
                        <Header content='Être alerté du lancement de la levee' />
                        <Modal.Content>
                            <p>
                                Merci de vous <a href="/login"> connecter </a> ou vous <a href="/login">inscrire</a> pour être alerté le jour du l'ouverture de la levée !
                            </p>
                        </Modal.Content>
                    </Modal>
                </div>
            )}

            {profile === ProfilCode.INVESTISSEUR && project.StatutProjetCode === StatutProjet.SUSPENDED && (
                <div onClick={handleSuspensionButtonClick}>
                    <Modal
                    open={suspendedOpen}
                    onClose={() => setSuspendedOpen(false)}
                    closeOnDocumentClick
                    trigger={
                        <Popup 
                        content={suspendedPopupMessage}
                        position='bottom center'
                        size='mini'
                        on='hover'
                        pinned
                        mouseEnterDelay={200}
                        mouseLeaveDelay={200}
                        hideOnScroll
                        trigger={
                            <div>          
                                <Button size="big" 
                                        color="blue" 
                                        fluid
                                >
                                    <Icon 
                                    css={{color:"white !important"}}
                                    size="large" name={isUserAlerted ? "bell" : "bell outline"}/>
                                    <span 
                                    css={{verticalAlign:"middle"}}>Etre alerté de la reprise</span>
                                </Button>
                            </div>
                        }
                        />    
                    }
                    >
                        <Header content='Etre alerté de la reprise de la levée' />
                        <Modal.Content>
                            <p>
                                Merci de vous <a href="/login"> connecter </a> ou vous <a href="/login">inscrire</a> pour être alerté le jour de la reprise de la levée !
                            </p>
                        </Modal.Content>
                    </Modal>
                </div>
            )}

            {profile === ProfilCode.INVESTISSEUR && 
            !loginProps.user?.Etablissement &&
            (loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.PRESQUE_COMPLET ||
            loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.ERREUR ||
            loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.COMPLET ||
            loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.VAL_EMAIL_A_FAIRE) && 
            !loginProps.user?.IsMailValid && 
                <Button 
                    size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                    color="blue" 
                    fluid
                    onClick={reload}
                >
                    J’ai validé mon adresse mail
                </Button>
            }

            {loginProps.oauth?.userId && profile === ProfilCode.INVESTISSEUR && project.StatutProjetCode === StatutProjet.PUBLIE && !isNotRestricted &&
                <div
                    style={{
                        fontSize: "1.2rem",
                        fontWeight:"bold",
                        textAlign: "center",
                        color: "#085c74"
                    }}
                >
                    Cette collecte est limitée à la zone suivante : {displayRestrictedDepartementsCode}
                </div>
            }

            {(project.StatutProjetCode === StatutProjet.FINANCE || (statutsClotures.find(status => status === project.StatutProjetCode))) && (
                <div
                    className="financement-close-text-area"
                >
                    Cette collecte est terminée ! L'objectif a été atteint en {Math.floor((endDate.getTime() - parseISO(project.DateDebutFinancementCitoyen!).getTime()) / (1000 * 3600 * 24)) + 1} jours
                </div>
            )}
                {!loginProps.oauth && project.StatutProjetCode === StatutProjet.PUBLIE && isNotRestricted && (
                    <div className={project.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON ? "" : "no-switch-link"}
                     >
                        <Button 
                            color="blue"
                            fluid
                            onClick={() => navigate(`/connexion`)}
                            size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                        >
                            Investir
                        </Button>
                    </div>
                )}
            </div>
            {project.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON && 
            isSwitchAvailable() && 
                <div 
                className="financement-switch-link "
                >
                    {loginProps.user?.Etablissement &&
                        <p>L'emprunt est réservé aux personnes physiques</p>
                    }
                    <LinkButton 
                        label="Voir la campagne en dons"
                        onlyBlack={true}
                        onClick={() => navigate(`/projets/${project.UrlSlug}/don`)}
                    />
                </div>
            }
            {/* BLOC 1 */}
            <div className="financement-caracteristiques-block">
                <div className="financement-caracteristiques-block-element">
                <span className="financement-caracteristiques-block-element-title-span">
                        <Icon name="money bill alternate" size="large" />
                        {project.TypeEmpruntCode === TypeEmprunt.TITRES ? "Taux fixe annuel" : "Taux d'intérêt"}
                    </span>
                    <span>{project.TauxInteretAnnuel}% par an</span>
                </div>
                <div className="financement-caracteristiques-block-element">
                        <span 
                        style={{ 
                                fontWeight: "bold",
                                display:"flex"
                            }}
                        >
                            <Icon name="calendar check" size="large" />
                            {project.TypeEmpruntCode === TypeEmprunt.TITRES ? "Période sans amortissement" : "Remboursement"}
                        </span>
                        <span>
                            {
                                project.TypeEmpruntCode === TypeEmprunt.TITRES ? 
                                    project.PeriodeSansRemboursement + " mois"
                                :
                                project?.TypologieRemboursementCode &&
                                references.typologie?.[project.TypologieRemboursementCode]
                            }
                        </span>
                </div>
            </div>
             {/* BLOC 2 */}
            <div className="financement-caracteristiques-block">
                <div 
                    className="financement-caracteristiques-block-element"

                >
                <span className="financement-caracteristiques-block-element-title-span">
                        <Icon name="flag checkered" size="large" />
                        Durée
                    </span>
                    <span>
                        {project.MaturiteCreditCitoyen ? `${project.MaturiteCreditCitoyen} mois` : ""}
                    </span>
                </div>
                <div 
                    style={{
                        width:"50%", 
                        display:"flex", 
                        flexDirection:"column"
                        }}
                >
                    <span className="financement-caracteristiques-block-element-title-span">
                        <Icon name="calculator" size="large" />
                        Échéances
                    </span>
                    <span>
                        {project?.PeriodiciteEcheanceCitoyenCode &&
                            references.periodicity?.[
                            project.PeriodiciteEcheanceCitoyenCode
                        ]}
                    </span>
                </div>
            </div>
            {/* BLOC 3 */}
            <div className="financement-caracteristiques-block">
                <div className="financement-caracteristiques-block-element">
                <span className="financement-caracteristiques-block-element-title-span">
                        <Icon name="flag checkered" size="large" />
                        Type d'emprunt
                    </span>
                    <span>
                        {TypeEmpruntLibelle.find(tel => tel.Code === project.TypeEmpruntCode)?.Libelle}
                    </span>
                </div>
                <div className="financement-caracteristiques-block-element">
                    <span 
                        style={{ 
                            fontWeight: "bold",
                            display:"flex", 
                            alignItems:"center"
                        }}
                    >
                        <Icon name="money bill alternate" size="large" />
                        Investissement max
                    </span>
                    <span>
                        {project.MontantMaximalInvestissement ? project.MontantMaximalInvestissement / 100 : 0} €
                    </span>
                </div>
            </div>
               {/* BLOC 4*/}            
            <div className="financement-informations-links">
                <div>
                    <a href="/risques" target="_blank" style={{fontWeight: "bold"}}>Risques</a>
                </div>
                <div>
                    <a href="/faq-page" target="_blank" style={{fontWeight: "bold"}}>FAQ</a>
                </div>
                <div>
                    <a href="/faq-page#fiscalite-citoyen" target="_blank" css={{fontWeight: "bold"}}>Fiscalité</a>
                </div>
            </div>
        </div>
    );
}
