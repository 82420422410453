import React from 'react'
import { Form } from 'semantic-ui-react'
import { InputField } from '../../../generic/input-field'
import IconButton from '../../../generic/icon-button'

type Props = {
    data: ObjectifFieldData,
    id?: number,
    error?: string,
    handleInputChange : (e:React.ChangeEvent<HTMLInputElement>, fieldName:string, order: number) => void,
    onDelete : ()=> void
    isValidateField?: (value: any) => boolean
    
}

type FieldData = {
  value?: any,
  name: string,
  isValid?: boolean,
  key?: string,
};

type ObjectifFieldData = FieldData & {
  id?: number,
  order: number
}

export default function ObjectifProjetField({data, id, error,  handleInputChange, onDelete, isValidateField}: Props) {
  const inputFieldData: FieldData = {
    name: data.name,
    isValid: data?.isValid,
    value:data.value,
    key: data?.key
  }

  return (
    <div style={{marginBottom:10}}>
        <div style={{display: 'flex', flexDirection:'column'}}>
          <div style={{ display:'flex', alignItems:'center'}}>
            <Form.Field size='tiny'> 
                <InputField 
                  label={data?.key}
                  type='tel'
                  data={inputFieldData}
                  onChange={(e) => handleInputChange(e, inputFieldData.name, data.order)}
                  validateField={isValidateField}
                  error={error}
                  inputCss={{maxWidth : '150px'}}
                />
            </Form.Field>
            <IconButton 
            onClick={onDelete}
            style={{ marginLeft:20,  marginBottom:8, alignSelf:'self-end'}} 
            icon='/img/icons/moins.svg'
            />
          </div>
        </div>
        
    </div>
  )
}
