import React, { useEffect, useState } from 'react'
import Projet from '../../../model/projet'
import { Form } from "semantic-ui-react"
import { AuthenticationState } from '../../../model/state/authentication-state'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../store'
import { FlowStep } from '../../../model/entities/flow-step'
import { referenceService } from '../../../services/reference-service'
import { WorkflowProcessResultResponse } from '../../../model/dto/response'
import { SimpleButton } from '../../generic/simple-button'
import { SubFlowStep } from '../../../model/entities/sub-flow-step'
import { RegisterObjectifsProjetBody } from '../../../model/dto/body/register-objectifs-projet-body'
import { projetService } from '../../../services/projet-service'
import { SubmitButton } from '../../generic/submit-button'
import ProjetObjectifs from '../../../model/entities/projet-objectifs'
import ObjectifProjetField from '../detail/parts/objectif-projet-field'
import { TypeCollecte } from '../../../model/entities/type-collecte'
import IconButton from '../../generic/icon-button'
import { FormErrorHeader } from '../../generic/form-error-header'

type Props = {
    projet: Projet,
    isDon?: boolean,
    isCreating?: boolean,
    workflowTypeCode?: string,
    objectifs?: ProjetObjectifs[],
    onSkipStep?: (response: WorkflowProcessResultResponse) => void,
    onSubmitSuccess: (response: Response) => void,
    onLeavingWithoutChange?: () => void
}

type FieldData = {
    value?: any,
    name: string,
    isValid?: boolean,
    key?: string,

  };

  type ObjectifFieldData = FieldData & {
    id?: number,
    order: number
  }

export default function ObjectifsForm({projet, isDon, isCreating, workflowTypeCode, objectifs, onSkipStep, onSubmitSuccess, onLeavingWithoutChange} : Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const [updateFormAfterDelete, setUpdateFormAfterDelete] = useState(false)
    const [form, setForm] = useState<ObjectifFieldData []>([])
    const [isFormError, setIsFormError] = useState(false)
    const [isFieldError, setIsFieldError] = useState(false)

    useEffect(() => {
        if(updateFormAfterDelete){
            let formOrder = 0
            const formListWithUpdate = form
            formListWithUpdate.forEach(obj => {
                obj.key = createOrderLabel(formOrder)
                obj.order = formOrder + 1
                obj.isValid = true
                formOrder ++
            })
            setForm(formListWithUpdate)
            setUpdateFormAfterDelete(false)
        }
    }, [updateFormAfterDelete]) 

    useEffect(() => {
        if(objectifs && objectifs.length > 0){
            let existingObjectifs: ObjectifFieldData[] = []
            objectifs.forEach((objectif) => {
                const objectifInFormField: ObjectifFieldData = {
                    id : objectif.Id,
                    name: createFieldName(existingObjectifs),
                    value: objectif.Montant! / 100,
                    key: createOrderLabel(existingObjectifs.length).toString(),
                    order: objectif.Order ? objectif.Order : objectifs.findIndex(el => el.Montant === objectif.Montant!) +1 ,
                    isValid: true
                }
                existingObjectifs.push(objectifInFormField)
            })
            setForm(existingObjectifs)
        }
    },[])

    const nextObjectifsFormStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode ?? '',
            CurrentFlowStep : isDon ? FlowStep.FINANCEMENT_DES_DONS : FlowStep.FINANCEMENT_PARTICIPATIF,
            RessourceId:  projet?.Id!,
            SubFlowStep: isDon ? SubFlowStep.OBJECTIFS_PROJET_DON :SubFlowStep.OBJECTIFS_PROJET_EMPRUNT,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
            onSkipStep && onSkipStep(response)})
    }

    const removeObjectif =(key?: string) => {
        const afterRemoveObjectifList = form.filter(obj => obj.name !== key)
        setForm(afterRemoveObjectifList)
        return setUpdateFormAfterDelete(true)
    }

    const createOrderLabel = (order:number) => {
        if(order === 0){
            return '1er objectif'
        }
        return `${order + 1}ème objectif`
    }

    const createFieldName = (objectifList: FieldData[]) => {
        if(objectifList.length === 0){
            return 'field-1'
        }else {
            const lastCurrentFieldOrder: number = parseInt(objectifList[objectifList.length -1].name.split('-')[1])
            return `field-${lastCurrentFieldOrder + 1}`
        }
    }

    const addObjectifField = () => {
        const newInputField: ObjectifFieldData = {name : createFieldName(form), value:'', key:createOrderLabel(form.length).toString(), order: form.length + 1}
        const newFormList = [...form, newInputField]
        return setForm(newFormList)
    }

    const convertObjectifListToBackend = (): ProjetObjectifs[] => {
        let list:ProjetObjectifs[] = []
        form.forEach(obj => {
            const objToBack = {
                Id: obj?.id,
                ProjetId: projet.Id,
                Montant: parseInt(obj.value) * 100,
                TypeCollecteCode: isDon ? TypeCollecte.DON : TypeCollecte.EMPRUNT_CITOYEN,
                Order: obj.order
            }
            list.push(objToBack)
        })
        return list
    }

    const body: RegisterObjectifsProjetBody = {
        WorkflowTypeCode : workflowTypeCode,
        IsDon: isDon,
        ObjectifsList: convertObjectifListToBackend()
    }

    const montantMaxCollecte = isDon ? projet.MontantMaxCollecteDon! / 100 : projet.MontantMaximalFinancement! / 100

    const handleInputChange = (inputValue: React.ChangeEvent<HTMLInputElement>, fieldName: string, order: number): void => {
        setIsFormError(false)
        const updatedForm = form.map(element => {
            if(element.name === fieldName){
                return {
                    ...element,
                    value: inputValue.target.value.toString()
                }
            }
            return element
        })
        setForm(updatedForm)
    }

    const controlFieldOrderAmount = () => {
        let isErrorInOrder = false
        form.forEach(field => {
            const fieldMontant = parseInt(field.value)
            if(form.length > 1){
                    if(field.order === 1){
                        const nextMontant = parseInt(form[field.order].value)
                        if(nextMontant <= fieldMontant){
                            isErrorInOrder = true
                        }
                        
                    }else if(field.order === form.length){
                        const previousMontant = parseInt(form[field.order - 2].value)
                        if(fieldMontant >= montantMaxCollecte || fieldMontant <= previousMontant){
                            isErrorInOrder = true
                        }
                    } else {
                        const previousMontant = parseInt(form[field.order - 2].value)
                        const nextMontant = parseInt(form[field.order].value)
                        if(nextMontant <= fieldMontant || fieldMontant <= previousMontant || fieldMontant >= montantMaxCollecte){
                            isErrorInOrder = true
                        }
                    }
            }
            return true
        })
        
        return isErrorInOrder
    }

    const isFormValid = ():boolean => {
        if(form.length <= 0 || !form){
            return false
        }else {
            const isEmptyValue = form.find(fieldata => fieldata.value === '' || fieldata.value === '0')
            return isEmptyValue === undefined && controlFieldOrderAmount() === false
        }
    }

    const isValidateField = (newValue: string, fieldName: string): boolean => {
        setIsFieldError(false)
        const currentField = form.find(el => el.name=== fieldName)

        if(newValue === '' || !newValue){
            return false
        }
        const newValueInNumber = parseInt(newValue) ?? 0
        if(currentField?.order === 1){
            if(newValueInNumber < montantMaxCollecte ){
                return true
            }else {
                setIsFieldError(true)
                return false
            }
        }

        const previousFieldValue = parseInt(form[currentField!.order - 2].value)
        if(newValueInNumber > previousFieldValue && newValueInNumber < montantMaxCollecte){
            return true
        } else {
            setIsFieldError(true)
            return false
        }
    }

    const validationFormErrorMessage = "Veuillez vérifier les champs saisis. Aucun d'eux ne doit avoir de valeur nulle ou être vide, chaque valeur d'un champ ne peut pas être inférieure à celle du champ précédent"

    const onchangeFieldErrorMessage = "La valeur que vous renseignez ne peut être inférieure à celle du champ précédent, ou être égale au montant max de la collecte."

    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

  return (
    <Form>
        <Form.Field>Afin d'améliorer l'engagement des citoyens pour le financement de votre projet, vous pouvez définir jusqu'à neufs paliers de financement. Une fois définis, ces paliers remplaceront l'objectif. Si vous n'en définissez pas, l'objectif sera utilisé.</Form.Field>
        <Form.Field>Montant maximal de financement défini : {isDon ? projet.MontantMaxCollecteDon! / 100 : projet.MontantMaximalFinancement! / 100} €</Form.Field>
        <Form.Group>

        <Form.Group>
            <div style={{ display:'flex', flexDirection:'column', marginLeft:15}}>
            <FormErrorHeader 
                message={validationFormErrorMessage}
                displayed={isFormError}
            />
            <FormErrorHeader 
                message={onchangeFieldErrorMessage}
                displayed={isFieldError}
            />
                    {form && form.map((objectif => {
                        return <ObjectifProjetField 
                            key={`obj-${objectif.name}`} 
                            handleInputChange={handleInputChange} 
                            onDelete={() => removeObjectif(objectif.name)} 
                            data={objectif} 
                            isValidateField={(value) => isValidateField(value, objectif.name)} 
                            error={"Valeur incorrecte"}
                        />
                    }))}
                <IconButton 
                    icon='/img/icons/plus.svg'
                    onClick={() => addObjectifField()}
                    labelColor='#005870'
                    style={{fontWeight: '700', marginTop:20}}
                    label='Ajouter un objectif'/>
            </div>
        </Form.Group>
        </Form.Group>
        <div css={{
                display: 'flex',
                justifyContent: 'end'
            }}>
            <div css={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }} 
                className="button-bar">
                
                    <SimpleButton
                    label={isCreating ? "Ne pas définir de palier" : "Passer"} 
                    onClick={isCreating ? () => nextObjectifsFormStepKey() : () => onLeavingWithoutChange && onLeavingWithoutChange()}
                />
                <SubmitButton 
                    data={body}
                    label="Valider"
                    action={() => projetService.registerObjectifsProjet(body,  loginProps?.oauth, projet?.Id)}
                    onActionSuccess={onSubmitSuccess}
                    onFormNotValid={onFormNotValid}
                    validateForm={isFormValid}
                />
                </div>
            </div>
    </Form>
  )
}