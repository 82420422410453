import React, { useEffect, useMemo, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Form } from "semantic-ui-react"
import { RegisterVerificationCollectiviteBody } from "../../model/dto/body/register-verification-collectivite-body"
import { OnboardingInvestisseurResponse, ProfilCode, QualiteRepresentantListResponse, UserInfoResponse } from "../../model/dto/response"
import { AuthenticationState } from "../../model/state/authentication-state"
import { userService } from "../../services/authentication-redux-service"
import { ApplicationState } from "../../store"
import { CheckBoxElement, CheckBoxListField } from "../generic/checkbox-list-field"
import { CheckBoxSimpleField } from "../generic/checkbox-simple-field/checkbox-simple-field"
import { FormErrorHeader } from "../generic/form-error-header"
import { InputField } from "../generic/input-field"
import { SubmitButton } from "../generic/submit-button"
import { GetRepresentantTypeListBody } from "../../model/dto/body/get-representant-type-list-body"
import { referenceService } from "../../services/reference-service"
import { QualiteRepresentant } from "../../model/entities/qualite-representant"
import { SimpleButton } from "../generic/simple-button"
import { DropdownListFieldNew } from "../generic/dropdown-list-field"
import { Pays } from "../../model/entities/pays"
import { TypeCollectivite } from "../../model/entities/type-collectivite"
import { RegisterVerificationCollectiviteAdminBody } from "../../model/dto/body/register-verification-collectivite-admin-body"
import { CategorieEtablissement } from "../../model/entities/categorie-etablissement"
import { CollectiviteSelector } from "../generic/collectivite-selector/collectivite-selector"
import { Collectivite } from "../../model/entities/profil"
import { TypeInvestisseur } from "../../model/entities/types-investisseur"
import { AuthenticationAction, CHANGE_IDENTITY } from "../../actions/authentication-actions"
import { TypeInvestisseurMorale } from "../../model/entities/type-investisseur-morale"
import { convertDateToMaskPicker, isLegalAgeDate } from "../../helpers/date-helper"
import DateMaskFieldNew from "../generic/date-mask-field"
import { RadioListField } from "../generic/radio-list-field"
import { FlowStep } from "../../model/entities/flow-step"


type FieldData = {
    name: string,
    value?: any,
    isValid?: boolean
}

type FormData = {
    qualiteRepresentantId: FieldData,
    nom: FieldData,
    prenom: FieldData,
    dateNaissance: FieldData,
    autreFonction: FieldData,
    confirmationDelegation: FieldData,
    fiscLocal: FieldData,
    civilite: FieldData,
    nationalite: FieldData,
    paysNaissance: FieldData,
    villeNaissanceId: FieldData,
    nomDeCommuneNaissanceEtrangere: FieldData
}

interface QualiteRepresentantItem extends QualiteRepresentant {
    IsChecked?: boolean
}

type Props = {
    loginProps?: AuthenticationState,
    user?: UserInfoResponse,
    userId?: number,
    isOnboarding?: boolean,
    workflowTypeCode?: string,
    onSkipStep?: (response: Response) => void,
    onSubmitSuccess: (response: Response) => void,
    changeIdentity?: (userInfo: UserInfoResponse) => void
    
}

function SubscriptionIdentiteRepresentantForm ({loginProps, user, userId, isOnboarding, workflowTypeCode, onSkipStep, onSubmitSuccess }: Props) {
    
    const dispatch = useDispatch()
    
    const validateMandatory = (value: any): boolean => {
        return value && value !== ""
        
    }

    const validatePaysNaissance = (value: any): boolean => {
        if(loginProps?.user?.Etablissement?.CategorieCode === CategorieEtablissement.SOCIETE) {
            return validateMandatory(value)
        }
        else {
            return true
        }
    }
    
    const validateQualiteRepresentant = (value : any): boolean => {
        if(loginProps?.oauth?.categorieEtablissement === CategorieEtablissement.COLLECTIVITE) {
            return validateMandatory(form.qualiteRepresentantId.value)         
        } else {
            return true
        }
    }

    const validateChecked = (value?: boolean): boolean => {
        if(value === undefined) {
            return false
        } else {
            return value
        }
    }
    
    const onSubmitIdentiteSuccess = (response: OnboardingInvestisseurResponse) => {
        if(response.UserInfo) {
            const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: response.UserInfo }
            localStorage.setItem("user_info", JSON.stringify(response.UserInfo))
            dispatch(action)
        }
        onSubmitSuccess(response)
    }


    const [formSubmitted, setFormSubitted] = useState(false)
    const [isFormError, setIsFormError] = useState(false)
    const [qualiteRepresentantList, setQualiteRepresentantList] = useState<QualiteRepresentantItem[]>([])
    const [form, setForm] = useState<FormData>({
        prenom: {value: user?.Prenom ?? '', name: 'prenom', isValid: validateMandatory(user?.Prenom)},
        nom: {value: user?.Nom ?? '', name: 'nom', isValid: validateMandatory(user?.Nom)},
        qualiteRepresentantId: {value: user?.QualiteRepresentantId ?? '', name: 'qualiteRepresentantId', isValid: validateMandatory(user?.QualiteRepresentantId)},
        dateNaissance: {value: user?.DateNaissance ?? '', name: 'dateNaissance', isValid: validateMandatory(user?.DateNaissance)},
        autreFonction: {value: user?.AutreFonction ?? '', name: 'autreFonction', isValid: true},
        fiscLocal: {value: user?.IsResidentFrancais ?? false, name: 'fiscLocal', isValid: true},
        confirmationDelegation: {value: user?.ConfirmationDelegation ?? false, name: 'confirmationDelegation', isValid: true},
        civilite : {value: user?.Civilite ?? "", name: 'civilite', isValid: true },
        nationalite: {value: user?.Nationalite ?? "FR", name: 'nationalite', isValid: true},
        paysNaissance: {value: user?.PaysNaissanceCode ?? "FR", name: 'paysNaissance', isValid: true},
        villeNaissanceId: {value: user?.CommuneNaissanceId ?? "", name: 'villeNaissanceId', isValid: true},
        nomDeCommuneNaissanceEtrangere: {value: user?.NomDeCommuneEtrangere ?? "", name: 'nomDeCommuneNaissanceEtrangere', isValid: true}
    })
    const [isAutre, setIsAutre] = useState(false)
    const [isDelegationNecessaire, setIsDelegationNecessaire] = useState(false)
    const [paysList, setPaysList] = useState<Pays[]>([])
    const [isDateError, setIsDateError] = useState<boolean>(false)
    const [dateErrorMessage, setDateErrorMessage] = useState<string>('')

    useEffect(() => {
        const body: GetRepresentantTypeListBody = {
            TypeCollectiviteCode: loginProps?.user?.Etablissement?.TypeCollectiviteCode
        }
        const bodyAdmin: GetRepresentantTypeListBody = {
            TypeCollectiviteCode: user?.Etablissement?.TypeCollectiviteCode
        }
        if ( loginProps?.user?.ProfilCode === ProfilCode.PORTEUR_PROJET || loginProps?.user?.ProfilCode === ProfilCode.INVESTISSEUR)
        {
            referenceService.getQualiteRepresentantListForTypeCollectivite(loginProps?.oauth, body)
            .then(response => {
                return response.json() as Promise<QualiteRepresentantListResponse>
            })
            .then(response => {
                setQualiteRepresentantList(response.QualiteRepresentantList && response.QualiteRepresentantList.map(qualite => {
                    return {
                        Id: qualite.Id,
                        Designation: qualite.Designation,
                        IsAutre: qualite.IsAutre,
                        IsDelegationNecessaire: qualite.IsDelegationNecessaire,
                        TypeCollectiviteCode: qualite.TypeCollectiviteCode,
                        IsChecked: user?.QualiteRepresentantId === qualite.Id
                    } as QualiteRepresentantItem
                }) || [])
            })
        }
        else
        {
        referenceService.getQualiteRepresentantListForTypeCollectivite(loginProps?.oauth, bodyAdmin)
        .then(response => {
            return response.json() as Promise<QualiteRepresentantListResponse>
        })
        .then(response => {
            setQualiteRepresentantList(response.QualiteRepresentantList && response.QualiteRepresentantList.map(qualite => {
                return {
                    Id: qualite.Id,
                    Designation: qualite.Designation,
                    IsAutre: qualite.IsAutre,
                    IsDelegationNecessaire: qualite.IsDelegationNecessaire,
                    TypeCollectiviteCode: qualite.TypeCollectiviteCode,
                    IsChecked: user?.QualiteRepresentantId === qualite.Id
                } as QualiteRepresentantItem
            }) || [])
        })
        }
        referenceService.getAllPays()
        .then(response => {
            setPaysList(response.PaysList!)
        })
    }, [])
   
    useEffect(() => {
        if(qualiteRepresentantList.find(qualite => qualite.Id === form.qualiteRepresentantId.value)?.IsAutre) {
            setIsAutre(true)
        } else {
            setIsAutre(false)
        }

        if(qualiteRepresentantList.find(qualite => qualite.Id === form.qualiteRepresentantId.value)?.IsDelegationNecessaire) {
            setIsDelegationNecessaire(true)
        } else {
            setIsDelegationNecessaire(false)
        }
    }, [form.qualiteRepresentantId, qualiteRepresentantList])

    

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        setIsFormError(false)
        setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid}})
    }

    const handleDateChange = (date: Date | null, fieldName: string, isValid?: boolean): void => {
        setIsFormError(false)
        setIsDateError(false)
        setForm({...form, [fieldName]: { value: date, name: fieldName, isValid: isValid }})
    }

    const preSubmit = (): void => {
        setFormSubitted(true)
    }

    const isFormValid = (): boolean => {
        return validateMandatory(form.prenom.value) && 
            validateMandatory(form.nom.value) && 
            validateQualiteRepresentant(form.qualiteRepresentantId.value) &&
            validateDateNaissance(form.dateNaissance.value) &&
            validateAutreFonction(form.autreFonction.value) &&
            validateConfirmationDelegation(form.confirmationDelegation.value) &&
            validateMandatory(form.fiscLocal.value) &&
            validateMandatory(form.civilite.value) &&
            validateMandatory(form.nationalite.value) &&
            validatePaysNaissance(form.paysNaissance.value) &&
            validateVilleNaissanceId(form.villeNaissanceId.value) &&
            validateLieuNaissanceEtranger(form.nomDeCommuneNaissanceEtrangere.value)
    }

    const validateVilleNaissanceId = (value: any) => {
        if(loginProps?.user?.Etablissement?.CategorieCode === CategorieEtablissement.SOCIETE) {
            return form.paysNaissance.value !== 'FR' || validateMandatory(value)
        }

        return true;
    }

    const validateLieuNaissanceEtranger = (value : any) => {
        if(loginProps?.user?.Etablissement?.CategorieCode === CategorieEtablissement.SOCIETE) {
            if (form.paysNaissance.value !== "FR") {
                return validateMandatory(value)
            }
        }
        return true
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
        if(!form.dateNaissance.value){
            setIsDateError(true)
            setDateErrorMessage(wrongDateMessage)
        }
    }

    const onSubmitError = (): void => {
        console.log("erreur lors du submit")
    }

    const validateAutreFonction = (value: any): boolean => {
        if (loginProps?.oauth?.categorieEtablissement === CategorieEtablissement.COLLECTIVITE) {
            if(qualiteRepresentantList) {
                if(qualiteRepresentantList.find(qualite => qualite.Id === form.qualiteRepresentantId.value)?.IsAutre) {
                    return validateMandatory(value)
                }
            } 
        }
        return true
    }

    const validateConfirmationDelegation = (value: any): boolean => {
        if (loginProps?.oauth?.categorieEtablissement === CategorieEtablissement.COLLECTIVITE) {
            if(qualiteRepresentantList) {
                if(qualiteRepresentantList.find(qualite => qualite.Id === form.qualiteRepresentantId.value)?.IsDelegationNecessaire) {
                    return validateChecked(value)
                }
            } 
        }

        return true
    }

    const body: RegisterVerificationCollectiviteBody = {
        Nom: form.nom.value,
        Prenom: form.prenom.value,
        QualiteRepresentantId: form.qualiteRepresentantId.value,
        Civilite: form.civilite.value,
        DateNaissance: form.dateNaissance.value,
        AutreFonction: form.autreFonction.value,
        ConfirmationDelegation: form.confirmationDelegation.value,
        ResidenceFiscale: form.fiscLocal.value,
        Nationalite: form.nationalite.value,
        PaysNaissanceCode: form.paysNaissance.value,
        CommuneNaissanceId: form.villeNaissanceId.value,
        NomCommuneNaissanceEtranger: form.nomDeCommuneNaissanceEtrangere.value,
        WorkflowTypeCode: workflowTypeCode
    }
    const bodyAdmin: RegisterVerificationCollectiviteAdminBody = {
        UserId: userId!,
        Nom: form.nom.value,
        Prenom: form.prenom.value,
        QualiteRepresentantId: form.qualiteRepresentantId.value,
        Civilite: form.civilite.value,
        DateNaissance: form.dateNaissance.value,
        AutreFonction: form.autreFonction.value,
        ConfirmationDelegation: form.confirmationDelegation.value,
        ResidenceFiscale: form.fiscLocal.value,
        Nationalite: form.nationalite.value,
        PaysNaissanceCode: form.paysNaissance.value,
        CommuneNaissanceId: form.villeNaissanceId.value,
        NomCommuneNaissanceEtranger: form.nomDeCommuneNaissanceEtrangere.value
    }

    const isQualiteDiplayed = useMemo(() => {
        return (loginProps?.oauth?.categorieEtablissement === CategorieEtablissement.COLLECTIVITE) || (loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseur.COLLECTIVITE)
    }, [loginProps]) 

   
    const wrongDateMessage = "Merci de renseigner la date de naissance du bénéficiaire au format JJ/MM/AAAA"
    const legalAgeDateMessage = "Conformément aux CGU vous devez avoir au moins 18 ans pour utiliser les services de la plateforme"

    const validateDateNaissance = (date: Date | string) => {
        if(!date){
            setIsDateError(true)
            setDateErrorMessage(wrongDateMessage)
            return false
        } else {
            if(typeof(date) === 'string'){
                return date = new Date(date)
            }
            if(!isLegalAgeDate(date)) {
                setIsDateError(true)
                setDateErrorMessage(legalAgeDateMessage)
                return false
            }
            setIsDateError(false)
            return true 
        }
    }

    const nextStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode!,
            CurrentFlowStep : FlowStep.IDENTITE_REPRESENTANT_LEGAL,
            RessourceId:  loginProps?.user?.UserId!,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
            onSkipStep && onSkipStep(response)})
      }

    return (
        <div>
            <Form>
            {isOnboarding && (<h1>Identité du représentant légal</h1>)}
                <FormErrorHeader 
                    message="Veuillez corriger les champs en erreur"
                    displayed={isFormError}
                />
                <Form.Group>
                    <Form.Field>
                        <RadioListField
                            label="Civilité*"
                            elements={[
                                { label: "Madame", key: "Madame" },
                                { label: "Monsieur", key: "Monsieur" },
                            ]}
                            data={form.civilite}
                            onChange={(value: string) => {
                                setForm({
                                  ...form,
                                  civilite: { name: "civilite", value, isValid: true },
                                });
                              }}
                            validateField={validateMandatory}
                            submitted={formSubmitted}
                            error="Merci d'indiquer votre civilité"
                        />
                    </Form.Field>
                </Form.Group>
                {isQualiteDiplayed &&
                    <CheckBoxListField
                        data={form.qualiteRepresentantId}
                        label="Qualité représentant*"
                        submitted={formSubmitted}
                        elements={qualiteRepresentantList && qualiteRepresentantList.map(qualite => {
                            return {
                                key: qualite.Id,
                                label: qualite.Designation
                            } as CheckBoxElement
                        }) || []}
                        error="Merci de renseigner votre fonction"
                        onChange={(newKey: any, isValid?: boolean) => {
                            setForm({...form, qualiteRepresentantId: {...form.qualiteRepresentantId, value: newKey, isValid: isValid}})
                            setIsFormError(false)
                        }}
                        validateField={validateMandatory}
                    />
                }
                {isAutre &&
                <Form.Group>
                    <Form.Field width={16}>
                        <InputField 
                            data={form.autreFonction}
                            label="Désignation fonction*"
                            submitted={formSubmitted}
                            error="Merci de renseigner votre fonction"
                            validateField={validateAutreFonction}
                            onChange={handleInputChange}
                        />
                    </Form.Field>
                </Form.Group>
                }
                {isDelegationNecessaire && loginProps?.user?.Etablissement?.CategorieCode === CategorieEtablissement.COLLECTIVITE  && loginProps?.user?.Etablissement?.TypeCollectiviteCode===TypeCollectivite.REGION &&
                    <Form.Group>
                        <Form.Field width={16}>
                            <CheckBoxSimpleField 
                                data={form.confirmationDelegation}
                                label="Je confirme avoir reçu la délégation de pouvoir engager des recherches de financement pour le compte de ma collectivité par un vote du conseil régional*"
                                error="Merci de confirmer cette mention"
                                submitted={formSubmitted}
                                onChange={(value: any, isValid: boolean) => {
                                    setForm({...form, confirmationDelegation: {...form.confirmationDelegation, value: value, isValid: isValid}})
                                    setIsFormError(false)
                                }}
                                validateField={validateConfirmationDelegation}
                            />
                        </Form.Field>
                    </Form.Group>
                }
                {isDelegationNecessaire && loginProps?.user?.Etablissement?.CategorieCode === CategorieEtablissement.COLLECTIVITE && loginProps?.user?.Etablissement?.TypeCollectiviteCode===TypeCollectivite.DEPARTEMENT &&  
                    <Form.Group>
                        <Form.Field width={16}>
                            <CheckBoxSimpleField 
                                data={form.confirmationDelegation}
                                label="Je confirme avoir reçu la délégation de pouvoir engager des recherches de financement pour le compte de ma collectivité *"
                                error="Merci de confirmer cette mention"
                                submitted={formSubmitted}
                                onChange={(value: any, isValid: boolean) => {
                                    setForm({...form, confirmationDelegation: {...form.confirmationDelegation, value: value, isValid: isValid}})
                                    setIsFormError(false)
                                }}
                                validateField={validateConfirmationDelegation}
                            />
                        </Form.Field>
                    </Form.Group>
                } 
                {isDelegationNecessaire && loginProps?.user?.Etablissement?.CategorieCode === CategorieEtablissement.COLLECTIVITE && loginProps?.user?.Etablissement?.TypeCollectiviteCode === TypeCollectivite.EPCI &&  
                    <Form.Group>
                        <Form.Field width={16}>
                            <CheckBoxSimpleField 
                                data={form.confirmationDelegation}
                                label="Je confirme avoir reçu la délégation de pouvoir engager des recherches de financement pour le compte de ma collectivité par un vote du conseil délibérant de mon Établissement public de coopération intercommunale (EPCI)*"
                                error="Merci de confirmer cette mention"
                                submitted={formSubmitted}
                                onChange={(value: any, isValid: boolean) => {
                                    setForm({...form, confirmationDelegation: {...form.confirmationDelegation, value: value, isValid: isValid}})
                                    setIsFormError(false)
                                }}
                                validateField={validateConfirmationDelegation}
                            />
                        </Form.Field>
                    </Form.Group>
                }  
                {isDelegationNecessaire && loginProps?.user?.Etablissement?.CategorieCode === CategorieEtablissement.COLLECTIVITE && loginProps?.user?.Etablissement?.TypeCollectiviteCode === TypeCollectivite.COMMUNE &&  
                    <Form.Group>
                        <Form.Field width={16}>
                            <CheckBoxSimpleField 
                                data={form.confirmationDelegation}
                                label="Je confirme avoir reçu la délégation de pouvoir engager des recherches de financement pour le compte de ma collectivité par un vote du conseil municipal*"
                                error="Merci de confirmer cette mention"
                                submitted={formSubmitted}
                                onChange={(value: any, isValid: boolean) => {
                                    setForm({...form, confirmationDelegation: {...form.confirmationDelegation, value: value, isValid: isValid}})
                                    setIsFormError(false)
                                }}
                                validateField={validateConfirmationDelegation}
                            />
                        </Form.Field>
                    </Form.Group>
                }      
                <Form.Group>
                    <Form.Field width={8}>
                        <InputField 
                            data={form.nom}
                            label="Nom*"
                            autocomplete="family-name"
                            submitted={formSubmitted}
                            error="Merci de renseigner votre nom"
                            validateField={validateMandatory}
                            onChange={handleInputChange}
                        />
                    </Form.Field>
                    <Form.Field width={8}>
                        <InputField 
                            data={form.prenom}
                            label="Prénom*"
                            autocomplete="given-name"
                            submitted={formSubmitted}
                            error="Merci de renseigner votre prénom"
                            validateField={validateMandatory}
                            onChange={handleInputChange}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={8}>
                        <DateMaskFieldNew
                            data={form.dateNaissance}
                            label="Date de naissance*"
                            className="field"
                            autoComplete="bday"
                            submitted={formSubmitted}
                            onChange={(
                                date: Date | null,
                                isValid?: boolean
                            ) => {
                                handleDateChange(date, form.dateNaissance.name, isValid);
                            }}
                            error={dateErrorMessage}
                            onError={isDateError}
                            validateField={validateMandatory}
                            formatModel="# # / # # / # # # #"
                            mask="_"
                            type="tel"
                            allowEmptyFormatting
                            value={user ? user.DateNaissance && convertDateToMaskPicker(user.DateNaissance.toString()): ''}
                        />
                    </Form.Field>
                    <Form.Field width={8}>
                        <DropdownListFieldNew
                            label="Nationalité*"
                            field={form.nationalite}
                            placeholder="Nationalité"
                            error="Merci de renseigner votre nationalité"
                            submitted={formSubmitted}
                            datasource={paysList.map((c) => ({
                                text: c.Nom!,
                                value: c.Code!,
                            }))}
                            onChange={(value) =>
                                handleInputChange(
                                { target: { value } } as any,
                                form.nationalite.name
                                )
                            }
                            validateField={validateMandatory}
                        />
                    </Form.Field>
                </Form.Group>
                {(loginProps?.user?.Etablissement?.CategorieCode === CategorieEtablissement.SOCIETE || loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseurMorale.SOCIETE) && (
                    <Form.Group>
                        <Form.Field width={8}>
                            <DropdownListFieldNew
                                label="Pays de naissance*"
                                field={form.paysNaissance}
                                placeholder="Pays de naissance"
                                error="Merci de renseigner votre pays de naissance"
                                submitted={formSubmitted}
                                datasource={paysList.map((c) => ({
                                    text: c.Nom!,
                                    value: c.Code!,
                                }))}
                                onChange={(value) =>
                                    handleInputChange(
                                    { target: { value } } as any,
                                    form.paysNaissance.name
                                    )
                                }
                                validateField={validateMandatory}
                            />
                        </Form.Field>
                        <Form.Field width={8}>
                            {form.paysNaissance.value === "FR" ? 
                                <CollectiviteSelector
                                    type={Collectivite.COMMUNE}
                                    label="Commune de naissance*"
                                    autocomplete="nope"
                                    useCodeUnique={false}
                                    initialCode={form.villeNaissanceId.value}
                                    submitted={formSubmitted}
                                    error="Merci de renseigner votre ville*"
                                    onChange={(value: any, isValid: boolean) => {
                                        setForm({...form, villeNaissanceId: {value: value, name: "villeNaissanceId", isValid: isValid}});
                                        setIsFormError(false)
                                    }}
                                    validateField={validateVilleNaissanceId}
                                />
                            :
                            <InputField
                                data={form.nomDeCommuneNaissanceEtrangere}
                                label="Nom de votre commune de naissance*"
                                submitted={formSubmitted}
                                onChange={handleInputChange}
                                error="Merci de renseigner le nom de votre commune de naissance"
                                validateField={validateLieuNaissanceEtranger}
                            />}
                        </Form.Field>
                    </Form.Group>
                )}
                <Form.Group>
                    <Form.Field width={8}>
                        <CheckBoxSimpleField
                        data={form.fiscLocal}
                        label="Je suis domicilié en France*"
                        error="Merci de confirmer cette mention"
                        submitted={formSubmitted}
                        onChange={(value: any, isValid: boolean) => {
                            handleInputChange(
                            { target: { value } } as any,
                            form.fiscLocal.name,
                            isValid
                            );
                        }}
                        validateField={validateMandatory}
                        />
                    </Form.Field>
                </Form.Group>

                <div style={{paddingBottom : "10px"}}>*champ obligatoire</div>
                
                <div className="button-bar">
                    <SubmitButton 
                        data={body}
                        label="Valider"
                        onPreSubmit={preSubmit}
                        action={() => {
                            if (loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET || loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR)
                            {
                                return userService.registerVerificationCollectivite(body, loginProps?.user?.UserId, loginProps?.user?.Etablissement?.Id, loginProps?.oauth)
                            }
                            else
                            {
                                return userService.registerVerificationCollectiviteByAdmin(bodyAdmin, loginProps?.oauth?.userId,user?.Etablissement?.Id, loginProps?.oauth)     
                            }
                        }}
                        validateForm={isFormValid}
                        onFormNotValid={onFormNotValid}
                        onActionSuccess={onSubmitIdentiteSuccess}
                        onActionFailure={onSubmitError}
                        debounceTimming={isOnboarding ? 1000 : undefined}
                    />
                    {
                        isOnboarding && (
                            <SimpleButton
                                label="Passer"
                                onClick={() => nextStepKey()}
                            />
                        )
                    }
                </div>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const mapDispatchToAction = {
    changeIdentity: (userInfo: UserInfoResponse) => ({type: 'CHANGE_IDENTITY', user: userInfo})
}

const ConnectedSubscriptionIdentiteRepresentantForm = connect(mapStateToProps, mapDispatchToAction)(SubscriptionIdentiteRepresentantForm)
export { ConnectedSubscriptionIdentiteRepresentantForm as SubscriptionIdentiteRepresentantForm }

