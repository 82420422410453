import './project-detail.css'
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { trackPromise } from "react-promise-tracker";
import { connect, useDispatch } from "react-redux";
import { Container, Grid, Icon, Segment } from "semantic-ui-react";
import { AuthenticationAction, CHANGE_IDENTITY } from "../../../actions/authentication-actions";
import { ProfilCode, ProjetDetailResponse } from "../../../model/dto/response";
import { EcheancierProjet } from "../../../model/entities/echeancier-projet";
import { LabelProjet } from "../../../model/entities/label-projet";
import { Profil } from "../../../model/entities/profil";
import { ProjetResources } from "../../../model/entities/projet-resources";
import ProjetRestrictedDepartement from "../../../model/entities/projet-restricted-departement";
import { SecteurProjet } from "../../../model/entities/secteur-projet";
import { StatutProjet } from "../../../model/entities/statut-projet";
import { TypeEmprunt } from "../../../model/entities/type-emprunt";
import Projet from "../../../model/projet";
import { userService } from "../../../services/authentication-redux-service";
import { investmentService } from "../../../services/investment-service";
import { projetService } from "../../../services/projet-service";
import { publicService } from "../../../services/public-service";
import { referenceService } from "../../../services/reference-service";
import { ApplicationState } from "../../../store";
import { AuthenticationState } from "../../../store/authentication/types";
import { LightEditableBlock } from "../../generic/editable-block/light-editable-block";
import { LoadingSpinner } from "../../generic/loading-spinner";
import { Slideshow } from "../../generic/slideshow";
import { FileDeletionForm } from "../file-deletion-form";
import { FileUploadForm } from "../file-upload-form";
import { ProjetDisclaimerProfil } from "../projet-disclaimer-profil";
import { SocialMediaShareBar } from "../social-media-share-bar";
import ActionAdminModificationFiche from "./actions-admin-fiche-informations";
import PartVariableInputForm from "./part-variable-input-form";
import GeneralInfos from "./parts/general-infos";
import SidebarInfos from "./parts/sidebar-infos";
import Status from "./parts/status";
import SummaryBlock from "./parts/summary-block/summary-block";
import { treatHttpResponseCode } from "../../../services/http-helper";
import { useNavigate } from "react-router-dom";
import { SummaryBlockDon } from "./parts/summary-block-don/summary-block-don";
import { TypeFinancementInfos } from "./parts/type-financement-infos";
import ProjetObjectifs from "../../../model/entities/projet-objectifs";
import { TypeCollecte } from "../../../model/entities/type-collecte";

export interface ProjetDetailProps {
  slug?: string,
  loginProps?: AuthenticationState;
  investementSucceded?: boolean;
  isPageDon?: boolean
}

export interface ProjectInfos {
  resources?: ProjetResources[];
  labels?: LabelProjet[];
  sectors?: SecteurProjet[];
  restrictedDepartements?: ProjetRestrictedDepartement[];
}

export interface References {
  sectors?: Record<string, string>;
  labels?: Record<string, string>;
  periodicity?: Record<string, string>;
  typologie?: Record<string, string>;
}

function ProjectDetail({ slug, investementSucceded, isPageDon, loginProps}: ProjetDetailProps) {
  const [project, setProject] = useState<Projet>();
  const [projectInfos, setProjectInfos] = useState<ProjectInfos>({});
  const [references, setReferences] = useState<References>({});
  const [reload, setReload] = useState(true);
  const [openPopUp, setOpenPopUp] = useState(investementSucceded);
  const [isProjetSuivi, setIsProjetSuivi] = useState(false)
  const [isUserAlerted, setIsUserAlerted] = useState(false)
  const [score, setScore] = useState(0)
  const [selectedResponseNumberList, setSelectedResponseNumberList] = useState<number[]>([])
  const [echeanciersProjet, setEcheanciersProjet] = useState<EcheancierProjet[]>([])
  const [categorieEtablissementCode, setCategorieEtablissementCode] = useState<string>()
  const [categorieEtablissementLibelle, setCategorieEtablissementLibelle] = useState<string>()
  const [typeCollectiviteCode, setTypeCollectiviteCode] = useState<string>()
  const [nomEtablissement, setNomEtablissement] = useState<string>()
  const [codeDepartement, setCodeDepartement] = useState<string>()
  const [nomLocalite, setNomLocalite] = useState<string>()
  const [nombreInvestisseur, setNombreInvestisseur] = useState(0)
  const [nombreDonateur, setNombreDonateur] = useState(0)
  const [projetObjectifs, setProjetObjectifs] = useState<ProjetObjectifs []>([])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (reload) {
      if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
        trackPromise(projetService.getProjetBySlug(loginProps?.oauth, slug, isPageDon)
        .then(response => {
          treatHttpResponseCode(response.status)
          if(response.redirected) {
            const tab = response.url.split('/')
            const slug = tab[tab.length - 1]
            const url = isPageDon ? `/projets/${slug}/don` : `/projets/${slug}`
            navigate(url);
          }
          return response.json() as Promise<ProjetDetailResponse>
        })
        .then(
          ({
            Projet,
            ProjetResourcesList,
            LabelProjetList,
            SecteurProjetList,
            RestrictedDepartementList,
            CategorieEtablissementLibelle,
            CategorieEtablissementCode,
            TypeCollectiviteCode,
            NomEtablissement,
            CodeDepartement,
            NomLocalite,
            NbInvestisseurs
          }) => {
            setProject(Projet);
            setProjectInfos({
              resources: ProjetResourcesList,
              labels: LabelProjetList,
              sectors: SecteurProjetList,
              restrictedDepartements: RestrictedDepartementList,
            });
            setReload(false);
            setCategorieEtablissementLibelle(CategorieEtablissementLibelle)
            setCategorieEtablissementCode(CategorieEtablissementCode)
            setTypeCollectiviteCode(TypeCollectiviteCode)
            setNomEtablissement(NomEtablissement)
            setCodeDepartement(CodeDepartement)
            setNomLocalite(NomLocalite)
            setNombreInvestisseur(NbInvestisseurs ?? 0)

            trackPromise(publicService.getObjectifsProjet(Projet?.Id, isPageDon ? TypeCollecte.DON : TypeCollecte.EMPRUNT_CITOYEN))
            .then(response => {
              treatHttpResponseCode(response.status)
              setProjetObjectifs(response.ObjectifList ? response.ObjectifList : [])
            })
          }
        ), "main");
      } else if(loginProps?.oauth?.profilCode === Profil.VILLYZ_USER) {
        trackPromise(projetService.getProjetBySlugAdmin(loginProps?.oauth, slug, isPageDon)
        .then(response => {
          treatHttpResponseCode(response.status)
          if(response.redirected) {
            const tab = response.url.split('/')
            const slug = tab[tab.length - 2]
            const url = isPageDon ? `/projets/${slug}/don` : `/projets/${slug}`
            navigate(url);
          }
          return response.json() as Promise<ProjetDetailResponse>
        })
        .then(
          ({
            Projet,
            ProjetResourcesList,
            LabelProjetList,
            SecteurProjetList,
            RestrictedDepartementList,
            CategorieEtablissementLibelle,
            CategorieEtablissementCode,
            TypeCollectiviteCode,
            NomEtablissement,
            CodeDepartement,
            NomLocalite,
            NbInvestisseurs
          }) => {
            setProject(Projet);
            setProjectInfos({
              resources: ProjetResourcesList,
              labels: LabelProjetList,
              sectors: SecteurProjetList,
              restrictedDepartements: RestrictedDepartementList
            });
            setReload(false);
            setCategorieEtablissementLibelle(CategorieEtablissementLibelle)
            setCategorieEtablissementCode(CategorieEtablissementCode)
            setTypeCollectiviteCode(TypeCollectiviteCode)
            setNomEtablissement(NomEtablissement)
            setCodeDepartement(CodeDepartement)
            setNomLocalite(NomLocalite)
            setNombreInvestisseur(NbInvestisseurs ?? 0)

            trackPromise(publicService.getObjectifsProjet(Projet?.Id, isPageDon ? TypeCollecte.DON : TypeCollecte.EMPRUNT_CITOYEN))
            .then(response => {
              treatHttpResponseCode(response.status)
              setProjetObjectifs(response.ObjectifList ? response.ObjectifList : [])
            })
          }
        ), "main");
      } else {
        if (loginProps?.oauth?.userId) {
          trackPromise(userService
            .getUserInfo(loginProps?.oauth?.userId, loginProps?.oauth)
            .then((response) => {
              const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: response }
              localStorage.setItem("user_info", JSON.stringify(response));
              dispatch(action);
            }), "main"
          )
        }

        trackPromise(publicService.getProjetBySlug(slug, isPageDon)
        .then(response => {
          treatHttpResponseCode(response.status)
          if(response.redirected) {
            const tab = response.url.split('/')
            const slug = tab[tab.length - 1]
            const url = isPageDon ? `/projets/${slug}/don` : `/projets/${slug}`
            navigate(url);
          }
          return response.json() as Promise<ProjetDetailResponse>
        })
        .then(
          ({
            Projet,
            ProjetResourcesList,
            LabelProjetList,
            SecteurProjetList,
            RestrictedDepartementList,
            CategorieEtablissementLibelle,
            CategorieEtablissementCode,
            TypeCollectiviteCode,
            NomEtablissement,
            CodeDepartement,
            NomLocalite,
            NbInvestisseurs
          }) => {
            setProject(Projet);
            setProjectInfos({
              resources: ProjetResourcesList,
              labels: LabelProjetList,
              sectors: SecteurProjetList,
              restrictedDepartements: RestrictedDepartementList,
            });
            setReload(false);
            setCategorieEtablissementLibelle(CategorieEtablissementLibelle)
            setCategorieEtablissementCode(CategorieEtablissementCode)
            setTypeCollectiviteCode(TypeCollectiviteCode)
            setNomEtablissement(NomEtablissement)
            setCodeDepartement(CodeDepartement)
            setNomLocalite(NomLocalite)
            setNombreInvestisseur(NbInvestisseurs ?? 0)

            trackPromise(publicService.getObjectifsProjet(Projet?.Id, isPageDon ? TypeCollecte.DON : TypeCollecte.EMPRUNT_CITOYEN))
            .then(response => {
              treatHttpResponseCode(response.status)
              setProjetObjectifs(response.ObjectifList ? response.ObjectifList : [])
            })
          } 
        ), "main");
      }
    }
  }, [reload]);

  useEffect(() => {
    trackPromise(Promise.all([
      referenceService.getAllSecteur().then((response) => response.json()),
      referenceService.getAllLabel().then((response) => response.json()),
      referenceService
        .getPeriodiciteList()
        .then((response) => response.json())
        .then((data) =>
          data.PeriodiciteList.reduce(
            (
              prev: Record<string, string>,
              cur: { Code: string; Libelle: string }
            ) => {
              prev[cur.Code] = cur.Libelle;
              return prev;
            },
            {}
          )
        ),
      referenceService
        .getAllTypologieRemboursement()
        .then((response) => response.json())
        .then((data) =>
          data.TypologieRemboursementList.reduce(
            (
              prev: Record<string, string>,
              cur: { Code: string; Libelle: string }
            ) => {
              prev[cur.Code] = cur.Libelle;
              return prev;
            },
            {}
          )
        ),
    ]).then(([sectors, labels, periodicity, typologie]) =>
      setReferences({ sectors, labels, periodicity, typologie})
    ), "main");
   
  }, []);

  useEffect(() => {
    if(project) {
      if(loginProps?.oauth?.profilCode === Profil.VILLYZ_USER) {
        trackPromise(investmentService.getEcheanciersProjetByProjetId(project.Id!, loginProps?.oauth)
        .then(response => setEcheanciersProjet(response.EcheanciersProjet!))
        );
      }
    }
    if(project && loginProps?.isAuthenticated && loginProps.oauth?.profilCode === Profil.INVESTISSEUR) {
      trackPromise(Promise.all([
        projetService.isProjetSuivi(project.Id!, loginProps?.oauth)
        .then(response => { 
          setIsProjetSuivi(response.IsAbo!)
        }),
        projetService.isUserAlerted(project.Id!, loginProps?.oauth)
        .then(response => {
          setIsUserAlerted(response.IsAlert!)
        }),
        userService.getResultatTestConnaissance(loginProps?.oauth?.userId, loginProps?.oauth)
        .then(response => {
          setScore(response.Score!);
          setSelectedResponseNumberList(response.ReponseNumeroList!)
        }),
      ]), "main")
    }
  }, [project])

  const profile = (loginProps?.oauth?.profilCode as ProfilCode) ?? ProfilCode.INVESTISSEUR;
  
  const listCodeNonEdit = [
    StatutProjet.EN_TRAITEMENT,
    StatutProjet.BIENTOT_DISPONIBLE,
    StatutProjet.PUBLIE,
    StatutProjet.REFUSE,
    StatutProjet.FINANCE, 
    StatutProjet.DEBOUCLAGE_DEMANDE, 
    StatutProjet.DEBOUCLAGE_POSSIBLE,
    StatutProjet.DEBOUCLAGE_VALIDE,
    StatutProjet.DEBOUCLAGE_FINALISE,
    StatutProjet.CLOTURE
  ];

  if (!project) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  var src = "/wireframe.png";
  if(projectInfos.resources && projectInfos.resources[0]) {
    src = `${process.env.REACT_APP_BASE_URL}/public/projets/${project.Id}/picture/${projectInfos.resources![0].Id}`;
  }

  return (
    <Container css={{ marginBottom: "40px", marginTop: "40px" }}>
      <Helmet>
        <meta property="og:title" content={`Villyz - ${project.Titre}`} />
        <meta property="og:description" content={project.DescriptionCourt} />
        <meta
          property="og:image"
          content={src}
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content={project.DescriptionCourt} />
        <meta property="og:site_name" content="Villyz" />
        <meta
          name="twitter:image:alt"
          content={`Le projet ${project.Titre} est à financer sur Villyz`}
        />
      </Helmet>
      <ProjetDisclaimerProfil 
        status={loginProps?.user?.OnboardingStatus?.Code} 
        profil={loginProps?.user?.ProfilCode}
        isMailValid={loginProps?.user?.IsMailValid}
        wallet={loginProps?.user ? loginProps?.user?.MangoPayLongWalletId : loginProps?.user?.Etablissement?.MangoPayLongWalletId}
        mangoPayId={loginProps?.user ? loginProps?.user?.MangoPayLongUserId : loginProps?.user?.Etablissement?.MangoPayLongWalletId}
        isDon={isPageDon ?? false}
      />
      
      {loginProps?.oauth?.profilCode === Profil.INVESTISSEUR && openPopUp &&
          <Segment css={{position:"relative", border:"2px solid #BED3AF !important", backgroundColor:"#E0F3D4 !important", display:"flex", flexDirection:"row", alignItems:"center"}}>
              <div css={{
                      position: "absolute", 
                      right:"5px", 
                      top:"5px", 
                      cursor:"pointer"
                    }} 
                    onClick={()=>setOpenPopUp(false)}
              >
                 <Icon name="times" css={{color:"#B0C6A3", "&:hover": {color: "#71797E"}}} size="large"></Icon>
              </div>
              <div css={{flexBasis: "10%", alignSelf:"flex-start"}}>
                <Icon name="check" css={{color:"#5D7150"}} size="huge"/>
              </div>
              <div css={{flexBasis: "90%"}}>
                <p 
                  css={{
                    color:"#5D7150", 
                    fontSize:"16px", 
                    fontWeight:"bold"}}
                >
                  Votre opération est en cours de traitement, vous pouvez suivre son avancement sur votre tableau de bord.
                  N'hésitez pas à partager votre engagement sur les réseaux sociaux !
                </p>
                <SocialMediaShareBar
                  url={window.location.href}
                  text={`Projet Villyz | ${project.Titre} est en financement`}
                />
              </div>
          </Segment>
      }
      <LoadingSpinner area="main">
      <Status statusCode={isPageDon ? project.StatutDonProjetCode : project.StatutProjetCode} project={project} profile={profile} isDon={isPageDon}/>
      {loginProps?.oauth && (loginProps.oauth?.profilCode === ProfilCode.VILLYZ || loginProps.oauth?.profilCode === ProfilCode.PORTEUR_PROJET) &&
        <TypeFinancementInfos 
        project={project}
        isPageDon={isPageDon ?? false}
        nonEditListCode={listCodeNonEdit}
        reload={() => setReload(true)}
        />
      }
      {
        project.StatutProjetCode === StatutProjet.FINANCE && project.TypeEmpruntCode === TypeEmprunt.TITRES &&
        <PartVariableInputForm 
          profile={profile}
          echeanciers={echeanciersProjet}
        />
      }
      
      <ActionAdminModificationFiche
        profile={profile}
        project={project}
        reload={() => setReload(true)}
        isPageDon={isPageDon}
      />
      <Grid stackable>
        <Grid.Row stretched>
          <Grid.Column
          mobile={11}
          tablet={10}
          computer={11}
          //  width={11}
           >
            <div css={{ position: "relative"}}>
              {project && (
                <LightEditableBlock
                  title="Images"
                  editable={(profile === ProfilCode.PORTEUR_PROJET && !(listCodeNonEdit.includes(project.StatutProjetCode!) || listCodeNonEdit.includes(project.StatutDonProjetCode!))) || profile === ProfilCode.VILLYZ}
                  icon="camera"
                  deleteIcon="trash"
                  containElements={projectInfos.resources?.map(res => res.Id!).length ? true : false}
                  iconCss={{
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                    zIndex: 10
                  }}
                  editor={(close, action) => (
                    action === "edit" ?
                        <FileUploadForm
                          projetId={project.Id!}
                          onUploadSuccess={() => setReload(true)}
                          closeModal={close}
                        />
                    :
                        <FileDeletionForm
                          projetId={project.Id!}
                          source={projectInfos.resources?.map(res => res.Id!)}
                          onRemoveSuccess={() => setReload(true)}
                          closeModal={close}
                        />
                  )}
                >
                  {projectInfos.resources?.length ? (
                    <Slideshow
                      projetId={project.Id}
                      source={projectInfos.resources.map(res => res.Id!)}
                    />
                    ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/wireframe.png"}
                      css={{ objectFit: "cover", maxHeight:"463px", width: "100%"}}
                      alt=" "
                    />
                  )}
                </LightEditableBlock>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
              mobile={5}
              tablet={6}
              computer={5}
              className="summary-block-card-container"
          >
            {isPageDon && (
              <SummaryBlockDon
                project={project}
                nonEditListCode = {listCodeNonEdit}
                categorieEtablissementCode={categorieEtablissementCode} 
                categorieEtablissementLibelle={categorieEtablissementLibelle}
                nombreDonateur={nombreDonateur}
                reload={() => setReload(true)}
                onPublishSuccess={() => setReload(true)}
                objectifs={projetObjectifs && projetObjectifs}
              />
            ) || (
              <SummaryBlock
                project={project}
                projetInfos={projectInfos}
                nonEditListCode = {listCodeNonEdit}
                references={references}
                profile={profile}
                isProjetFollowed={isProjetSuivi}
                isUtilisateurAlerted={isUserAlerted}
                score={score}
                reponseNumeroList={selectedResponseNumberList}
                reload={() => setReload(true)}
                onPublishSuccess={() => setReload(true)}
                categorieEtablissementCode={categorieEtablissementCode} 
                categorieEtablissementLibelle={categorieEtablissementLibelle}
                nombreInvestisseur={nombreInvestisseur}
                objectifs={projetObjectifs && projetObjectifs}
              />
            )}
            
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={11}>
            <GeneralInfos
              project={project}
              projectInfos={projectInfos}
              nonEditListCode={listCodeNonEdit}
              profile={profile}
              etablissementNom={nomEtablissement}
              typeCategorieEtablissementCode={typeCollectiviteCode}
              etablissementCategorie={categorieEtablissementCode}
              etablissementDepartementCode={codeDepartement}
              etablissementNomLocalite={nomLocalite}
              isPageDon={isPageDon}
              reload={() => setReload(true)}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <SidebarInfos
              profile={profile}
              project={project}
              nonEditListCode={listCodeNonEdit}
              isPageDon={isPageDon}
              reload={() => setReload(true)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </LoadingSpinner>
    </Container>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedProjetDetail = connect(mapStateToProps, null)(ProjectDetail);
export { ConnectedProjetDetail as ProjectDetail };